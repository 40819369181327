// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-docs-automatic-builds-mdx": () => import("./../../../../src/docs/automatic-builds.mdx" /* webpackChunkName: "component---src-docs-automatic-builds-mdx" */),
  "component---src-docs-faq-mdx": () => import("./../../../../src/docs/faq.mdx" /* webpackChunkName: "component---src-docs-faq-mdx" */),
  "component---src-docs-getting-started-mdx": () => import("./../../../../src/docs/getting-started.mdx" /* webpackChunkName: "component---src-docs-getting-started-mdx" */),
  "component---src-docs-intro-mdx": () => import("./../../../../src/docs/intro.mdx" /* webpackChunkName: "component---src-docs-intro-mdx" */),
  "component---src-docs-lambda-mdx": () => import("./../../../../src/docs/lambda.mdx" /* webpackChunkName: "component---src-docs-lambda-mdx" */),
  "component---src-docs-netlify-mdx": () => import("./../../../../src/docs/netlify.mdx" /* webpackChunkName: "component---src-docs-netlify-mdx" */),
  "component---src-docs-vercel-mdx": () => import("./../../../../src/docs/vercel.mdx" /* webpackChunkName: "component---src-docs-vercel-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

